<template>
  <div class="infomation main_top">
    <!-- <div class="container">
      <h1>{{ $t('key570') }}</h1>
      <h1>{{ $t('key571') }}</h1>

      <h2>{{ $t('key572') }}</h2>

      <p>
        {{ $t('key573') }}
      </p>
      <p>
        {{ $t('key574') }}
      </p>
      <h1>{{ $t('key575') }}</h1>
      <p>
        {{ $t('key576') }}
      </p>
      <p>
        {{ $t('key577') }}
      </p>
      <h1>{{ $t('key578') }}</h1>
      <p>
        {{ $t('key579') }}
      </p>
      <p><strong>{{ $t('key580') }}</strong></p>
      <p>
        {{ $t('key581') }}
      </p>
      <p><br /></p>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <td>
                <p><strong>{{ $t('key582') }}</strong></p>
              </td>
              <td>
                <p><strong>{{ $t('key583') }}</strong></p>
              </td>
              <td>
                <p>
                  <strong
                    >{{ $t('key584') }}</strong
                  >
                </p>
              </td>
              <td>
                <p>
                  <strong
                    >{{ $t('key585') }}
                  </strong>
                </p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p><strong>{{ $t('key586') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key587') }}
                </p>
              </td>
              <td>
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key590') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key591') }}
                </p>
              </td>
              <td>
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key592') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key593') }}
                </p>
              </td>
              <td>
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key599') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key594') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key595') }}
                </p>
              </td>
              <td>
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key596') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key597') }}
                </p>
              </td>
              <td>
                <p>{{ $t('key569') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key598') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key599') }}
                </p>
              </td>
              <td>
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p><br /></p>

      <p>
        {{ $t('key600') }}
      </p>
      <p><br /></p>

      <div class="table-container">
        <table>
          <thead>
            <tr>
              <td>
                <p><strong>{{ $t('key582') }}</strong></p>
              </td>
              <td>
                <p><strong>{{ $t('key601') }}</strong></p>
              </td>
              <td colspan="2">
                <p>
                  <strong
                    >{{ $t('key602') }}</strong
                  >
                </p>
              </td>
              <td>
                <p>
                  <strong
                    >{{ $t('key603') }}</strong
                  >
                </p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p><strong>{{ $t('key604') }}</strong></p>
              </td>
              <td colspan="2">
                <p>
                  {{ $t('key605') }}
                </p>
              </td>
              <td>
                <p>{{ $t('key606') }}</p>
              </td>
              <td colspan="2">
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key607') }} </strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key608') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key609') }}</strong></p>
              </td>
              <td>
                <p>{{ $t('key610') }}</p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key611') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key612') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key613') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key614') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key615') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key616') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key617') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key618') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key619') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key620') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key621') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key622') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key623') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key624') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key625') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key626') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key627') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key628') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key529') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key630') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{{ $t('key631') }}</strong></p>
              </td>
              <td>
                <p>
                  {{ $t('key632') }}
                </p>
              </td>
              <td colspan="2">
                <p>{{ $t('key588') }}</p>
              </td>
              <td>
                <p>{{ $t('key589') }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p><br /></p>

      <p>
        {{ $t('key633') }}
      </p>
      <p>
        {{ $t('key634') }}
      </p>
      <h1>{{ $t('key635') }}</h1>
      <p>
        {{ $t('key636') }}
      </p>
      <p>
        {{ $t('key637') }}
      
      </p>
      <p>
        {{ $t('key638') }}
      </p>
      <p><strong> {{ $t('key639') }}</strong></p>
      <p>
        {{ $t('key640') }}
      </p>
      <p><strong> {{ $t('key641') }}</strong></p>
      <p>
        {{ $t('key642') }}
      </p>
    </div> -->
    <div v-html="content"></div>
  </div>
</template>

<script>
import {serveMixins} from '../../mixins/serveMixins.js'
export default {
  mixins:[serveMixins],
};
</script>

<style>
</style>