import Vue from 'vue' //引入Vue
import Router from 'vue-router' //引入vue-router
import appMain from '../view/index/appMian.vue'
import  cookies from '../utils/cookie'
let token = cookies.getToken()
Vue.use(Router)


//Vue全局使用Router
// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
// push
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
 
// replace
const originalReplace = Router.prototype.replace
Router.prototype.replace= function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


export default new Router({
  mode:"history",
  // base:'/static/lootbar/',
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return {
      x: 0,
      y: 0
    }
  },

  routes: [ //配置路由，这里是个数组
    // {
    //   path: '*', 
    //   redirect: '/appMian',
    //   component: appMain,
    // },
    { 
      path: '/', 
      redirect: '/',
      component: appMain,
      children:[
        {
          path: '/:lang?',
          name: 'index',
          component: () => import('@/view/index/index.vue'),
          meta:{
            rule:false,
            hidenBto:false,
          }
        },
        {
          path: 'blog/:lang?',
          name: 'blog',
          component: () => import('@/view/index/blog.vue'),
          meta:{
            rule:false,
            hidenBto:true,
          },
          props:{
           default:true,
          }
        },
        {
          path: '/blogDetails/:lang?/:id',
          name: 'blogDetails',
          component: () => import('@/view/index/blogDetails.vue'),
          meta:{
            rule:false,
            hidenBto:true,
          }
        },
        {
          path: '/checkout/:lang?',
          name: 'checkout',
          component: () => import('@/view/index/checkout.vue'),
          meta:{
            hidenBto:false,
            rule:true
          }
        },
        {
          path: '/details/:lang?/:id',
          name: 'details',
          component: () => import('@/view/index/details.vue'),
          meta:{
            hidenBto:false,
            rule:false
          }
        },
        {
          path: '/order/:lang?',
          name: 'order',
          component: () => import('@/view/index/order.vue'),
          meta:{
            hidenBto:true,
            rule:true
          }
        },
        {
          path: '/search/:lang?/:search?',
          name: 'search',
          component: () => import('@/view/index/search.vue'),
          meta:{
            rule:false,
            hidenBto:true,
          }
        },
        {
          path: '/register/:lang?',
          name: 'register',
          component: () => import('@/view/index/register.vue'),
          meta:{
            hidenBto:true,
            rule:false
          }
        },
        {
          path: '/login/:lang?',
          name: 'login',
          component: () => import('@/view/index/login.vue'),
          meta:{
            hidenBto:true,
            rule:false
          }
        },
        {
          path: '/personal/:lang?/:tabId',
          name: 'personal',
          component: () => import('@/view/index/personal.vue'),
          meta:{
            hidenBto:true,
            rule:true
          }
        },
        {
          path: '/terms/:lang?/:id',
          name: 'terms',
          component: () => import('@/view/page/terms.vue'),
          meta:{
            hidenBto:false,
            rule:false
          }
        },
        {
          path: '/accountSet/:lang?',
          name: 'accountSet',
          component: () => import('@/view/index/accountSet.vue'),
          meta:{
            hidenBto:true,
            rule:true
          }
        },
        {
          path: '/aboutus/:lang?',
          name: 'aboutus',
          component: () => import('@/view/page/aboutus.vue'),
          meta:{
            hidenBto:true,
            rule:false
          }
        },
       
        {
          path: '/refund/:lang?',
          name: 'refund',
          component: () => import('@/view/page/refund.vue'),
          meta:{
            hidenBto:false,
            rule:false
          }
        },
        {
          path: '/cookiePolicy/:lang?/:id',
          name: 'cookiePolicy',
          component: () => import('@/view/page/cookiePolicy.vue'),
          meta:{
            hidenBto:false,
            rule:false
          }
        },
        {
          path: '/privacyPolicy/:lang?/:id',
          name: 'privacyPolicy',
          component: () => import('@/view/page/privacyPolicy.vue'),
          meta:{
            hidenBto:false,
            rule:false
          }
        },
        {
          path: '/faqs/:lang?/:id',
          name: 'faqs',
          component: () => import('@/view/page/faqs.vue'),
          meta:{
            hidenBto:false,
            rule:false
          }
        },
        {
          path: '/affiliate/:lang?',
          name: 'affiliate',
          component: () => import('@/view/page/affiliate.vue'),
          // beforeEnter: (to, from, next) => {
          //   if (!token) { 
          //     return
          //     // next() 
          //   } else {
          //     next('/affiliate') 
          //   }
          // },
          meta:{
            hidenBto:true,
            rule:true
          }
        },
        {
          path: '/guide/:lang?/:id',
          name: 'guide',
          component: () => import('@/view/page/guide.vue'),
          meta:{
            hidenBto:false,
            rule:false
          }
        },
        {
          path: '/agreement/:lang?/:id',
          name: 'agreement',
          component: () => import('@/view/page/agreement.vue'),
          meta:{
            hidenBto:false,
            rule:false
          }
        },
        {
          path: '/changePwd/:lang?',
          name: 'changePwd',
          component: () => import('@/view/page/changePwd.vue'),
          meta:{
            hidenBto:false,
            rule:true
          }
        },
      ]
    },
    
    
    
  ]
})