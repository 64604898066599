<template>
  <div class="blog_details main_top">
    <div class="blog_content">
        <div class="blog_title">{{blogContent.title}}</div>
        <!-- <div class="blog_msg" v-html="blogContent.remark"></div> -->
        <div class="blog_time">{{ blogContent.createTime }}</div>
        <div v-html="blogContent.content"></div>
    </div>
   
  </div>
</template>

<script>
import $ from 'jquery'
export default {
    data(){
        return{
            blogId:'',
            blogContent:{
                title:'',
                createTime:'',
                content:'',   
                remark:'',
            }
        }
    },
    created(){
        let blogId = this.$route.params.id
        this.blogId = blogId
     
        this.getBlogInfo()
    },
    methods:{
        getBlogInfo(){
            let language = this.$store.state.lang.dictValue
            let target_lang = localStorage.getItem('lang')
            let noId = this.blogId
            let _this = this
            if(!language){
                setTimeout(()=>{
                    this.getBlogInfo()
                },1000)
                return
            }
            this.$axiosApi.getBlogInfo({noId,language}).then(res=>{
                if(res.code == 200){
                    if(res.data.language!=language){
                        let id = res.data.id
                        this.translateBlog(id,target_lang,language)
                        

                        return
                    }
                    this.blogContent = res.data
                
                   
                }
            })
        },                                  
        translateBlog(id,target_lang,lang){
            this.$axiosApi.blogTranslation({id,target_lang,lang}).then(res=>{
                if(res.code == 200){
                    // let tranData = JSON.parse(res.data.data)
                    // this.blogConten.content = tranData.translations[0].text
                    this.blogContent = res.data.data
                    // console.log('tranData',tranData)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.blog_content{
    text-align: left;
    margin-top:16px;
    background:#fff;
    padding:32px;
    border-radius: 4px;
    .blog_title{
        font-size: 32px;
        font-style: normal;
        line-height: 40px;
        color: #363b3e;
        font-weight: 500;
        text-align: left;
    }
    .blog_msg{
        font-size: 18px;
        margin-top:10px;
    }
    .blog_time{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #a5a6a9;
        padding: 24px 0 16px;
        border-bottom: 1px solid #f2f2f2;
        text-align: left;
    }
}
@media screen and (max-width:768px){
    .blog_details{
       margin:0 -16px;
       .blog_content{
        margin:0!important;
        .blog_title{
            font-size: 20px;
            line-height: 28px;
        }
       }
    }
}

</style>