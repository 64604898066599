export const serveMixins = {
    data(){
        return{
            id:'',
            lang:'',
            content:'',
        }
    },
    created(){
        let id = this.$route.params.id
      
        this.id = id
        
        this.serviceDetailTranslation()
    },
    methods:{
        // getConfigService(){
        //     let id = this.id
        //     let language = this.$store.state.lang.dictValue
        //     let _this= this
        //     if(!language){
        //         setTimeout(()=>{
        //          _this.getConfigService()
        //         },1000)
        //         return
        //     }
        //     this.$axiosApi.getConfigService({id,language}).then(res=>{
        //         if(res.code == 200){
        //             if(res.data.language!=language){
        //                 this.serviceDetailTranslation()
        //                 return
        //             }
        //             this.content =res.data.detail 
                    
        //         }
        //     })
        // },
        serviceDetailTranslation(){
            let id = this.id
            let target_lang = localStorage.getItem('lang')
            let lang = this.$store.state.lang.dictValue
            let _this= this
                if(!lang){
                    setTimeout(()=>{
                     _this.serviceDetailTranslation()
                    },1000)
                    return
                }
            this.$axiosApi.serviceDetailTranslation({id,target_lang,lang}).then(res=>{
                if(res.code == 200){
                   
                    this.content =res.data.data.detail 
                    
                }
            })
        }
    }
    
}