// 'use strict'
// const path = require('path')

// function resolve(dir) {
//   return path.join(__dirname, dir)
// }
module.exports = {
  devServer: {
    // allowedHosts: 'all',
    disableHostCheck:true,
    hot: true,
    open: true,
    port: 8080,
    proxy:'http://192.168.1.11:8081/',
    // proxy:'http://192.168.1.13:8081/',
    // proxy:'http://150.109.12.35:8090/',   /* 正式 */
    // proxy: {
    //   [process.env.BASE_URL]: {
    //     target: 'http://192.168.1.13:8081/prod_api/', // 后端地址
    //     // secure: false, // 如果是https接口，需要配置这个参数
    //     // ws: true, // 是否代理websockets
    //     changeOrigin: true, // 在本地会创建一个虚拟服务端，然后发送请求的数据，并同时接收请求的数据，这样服务端和服务端进行数据的交互就不会有跨域问题
    //     pathRewrite: {
    //       ['^' +process.env.ASE_RUL]: ''
    //     }
    //   }
    // }


  },
  assetsDir: 'static',
  parallel: false,
  publicPath: '/',
  lintOnSave:false,
  css: {
    loaderOptions: {
      // 没有分号会报错
      sass: {
        data: '@import "@/style/root.scss";' //旧版sass-loader写法(8.0以下)
        // prependData: `@import "@/assets/css/base.scss";` //新版scss-loader(8.0及以上)
      }
    }  
  },
  // configureWebpack: {
  //   output: { /* 打包完成之后文件存放的配置项 */
  //     filename: 'index.js', /* 设置文件名 */
  //     path: path.join(__dirname, 'dist') /* 打包完成之后存放的目录*/
  //   },
  //   resolve: {
  //     alias: {
  //       '@': resolve('src')
  //     }
  //   },
  // }
  
}