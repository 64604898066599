<template>
  <div class="app_main">
    <div class="pc_content">
     <div class="px_container flex_col">
      <home-header></home-header>
      <router-view ></router-view>  

      <!-- <div class="dialog" v-show="dialogFlg"></div>
      <transition name="el-zoom-in-center">
        <div class="dialog_container" v-show="dialogFlg" >
          <div class="dialog_header">{{ dialogContent.title }}</div>
          <div class="dialog_content">
            <div class="dialog_message">
              {{ dialogContent.message }}
            </div>
          </div>
          <div class="dialog_footer flex_center" @click="close()">OK</div>
        </div>
      </transition> -->
      <home-footer v-show=!$route.meta.hidenBto></home-footer>
     </div>
        
    
      
    </div>
    <login-form v-if="showDialog"></login-form>
    <lang-form v-if="lang_flag"></lang-form>
    <!-- <div class="chat_box flex_center">
      <div class="chat_text hidden-xs-only" >24/7</div>
      <div class="chat_icon"><img src="../../assets/image/zxkf_icon.png" alt=""></div>
    </div> -->
  </div>
</template>

<script>
 import homeHeader from "../../components/header.vue";
 import homeFooter from '../../components/footer.vue'
 import loginForm from "../../components/loginForm.vue";
 import langForm from '../../components/lang.vue'

export default {

  components:{
    homeHeader,
    homeFooter,
    loginForm,
    langForm
    },
    
  data(){
    return{
      routerName:'',
      isRouterAlive: true
    }
  },
  created(){
    // this.languageAuto()
  },
  methods:{
    close(){
      this.$store.commit('setDialogFlg',false)
    },
    
  },
  computed:{
    showDialog(){return this.$store.state.showDialog},
    lang_flag(){return this.$store.state.lang_flag}
  },
 
}
</script>

<style lang="scss" scoped>
.app_main{
   
    width: 100%;
    display: flex;
    flex-direction: column;
    // min-height: 100vh;
    font-family:  -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif;

    .pc_content{
  
      flex:1;
      max-width: 1280px;
      width: 100% ;
      padding:0 40px;
      margin:0 auto;
 
      .px_container{
        width: 100%;
        min-height: 100vh;
        position: relative;
        
        .dialog{
          position: absolute;
          left:0;
          right:0;
          top:0;
          bottom:0;
          background: rgba($color: #000000, $alpha: 0.8);
          z-index: 99;
         
        }
        .dialog_container{
            position: absolute;
            top:35%;
            width:80%;
            margin-left:10%;
            background:#fff;
            font-size: 16px;
            border-radius: 10px;
            z-index: 100;
            // transform: scale(0);
            // transition:transform 0.3s ease-in-out 0.5s;
            
            .dialog_header{
              padding-top:25px;
            }
            .dialog_content{
              font-size: 14px;
              border-bottom:1px solid #ededf2;
              padding:12px 25px 27px;
            }
            .dialog_footer{
              color:#f77e1a;
              height: 50px;
              cursor: pointer;
            }
          }
      }
    }
    .chat_box{
      position: fixed;
      right: 32px;
      bottom: 80px;
      display: flex;
      padding: 8px 8px 8px 16px;
      height: 64px;
      border: 1px solid #edeef2;
      box-shadow:  0px 8px 10px -5px rgba(0, 0, 0, .08), 0px 16px 24px 2px rgba(0, 0, 0, .04), 0px 6px 30px 5px rgba(0, 0, 0, .05);;
      cursor: pointer;
      max-width: 160px;
      transform: translate(0);
      opacity: 1;
      background:#fff;
      transition: transform .2s ease-out, opacity .2s ease-out;
      border-radius: 32px;
      font-size: 14px;
      line-height: 20px;
      color:#1f2128;
      font-weight: 600;
      z-index: 999999;
      .chat_icon{
        width: 48px;
        height: 49px;
        margin-left:6px;
        
      }
    }
}
</style>