<template>
  <div class="affiliate main_top">
    <div class="affiliate_title">
        <div class="icon_back flex_center iconfont icon-back-line-free hidden-sm-and-up" @click="toBack()"></div>
        <div class="title_name "> {{ $t('key644') }}</div>
    </div>
    <div class="affiliate_container">
        <div class="container_thumb">
            <div class="thumb_title"> {{ $t('key671') }}</div>
            <div class="thumb_link">
                <div class="link_name"> {{ $t('key672') }}</div>
                <div class="link_group flex_start_center" >
                    <div class="link_input">
                        <el-input v-model="linkUrl" disabled></el-input>
                    </div>
                    <div class="link_copy flex" v-clipboard="()=>linkUrl"
                                                v-clipboard:success="onCopy"
                                                v-clipboard:error="onError">
                        <div class="link_icon flex_center"><svg t="1732614089847" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3241" width="16" height="16"><path d="M574 665.4c-3.1-3.1-8.2-3.1-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8c-3.1-3.1-8.2-3.1-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zM832.6 191.4c-84.6-84.6-221.5-84.6-306 0L410.3 307.6c-3.1 3.1-3.1 8.2 0 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6c-3.1 3.1-3.1 8.2 0 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1z" p-id="3242" fill="#282828"></path><path d="M610.1 372.3c-3.1-3.1-8.2-3.1-11.3 0L372.3 598.7c-3.1 3.1-3.1 8.2 0 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" p-id="3243" fill="#282828"></path></svg></div>
                        <div> {{ $t('key673') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container_thumb">
            <div class="thumb_title"> {{ $t('key674') }}</div>
            <div class="amount_thumb flex">
                <div class="amount_group">
                    <div class="amount_name"> {{ $t('key675') }} </div>
                    <div class="amount_price">$0</div>
                </div>
                <div class="amount_group">
                    <div class="amount_name"> {{ $t('key676') }}</div>
                    <div class="amount_price">$0</div>
                </div>
            </div>
            <div class="amount_thumb flex">
                <div class="amount_group">
                    <div class="amount_name"> {{ $t('key677') }}
                    </div>
                    <div class="amount_price">$0</div>
                </div>
               
            </div>
        </div>
        <div class="container_thumb">
            <div class="thumb_title flex">
                <div class="title_tab" @click="tabId = '1'" :class="[tabId==1?'is_active':'']"> {{ $t('key678') }}</div>
                <div class="title_tab"  @click="tabId = '2'" :class="[tabId==2?'is_active':'']"> {{ $t('key679') }}</div>
            </div>
            <div class="tabs_thumb">
                <div class="tab_income ">
                    <div class="empty_thumb flex_col_center">
                        <div class="empty_img"><img src="../../assets/image/empty.png" alt=""></div>
                        <div class="empty_text"> {{ $t('key682') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            linkUrl:'',
            tabId:1,
            code:'',
        }
    },
    created(){
        this.getUrl()
    },
    methods:{
        toBack(){
            this.$router.back()
        },
       
        getUrl(){
            let href = window.location.origin
            this.linkUrl = href+'?inviteUserCode='+this.userInfo.userCode
            console.log('href',href)
        },
        onCopy(e){
            console.log('copy success',e )
            this.$message.success('copy success')
        },
        onError(){
            console.log('copy err')
        }

    },
    computed:{
        userInfo(){ return this.$store.state.userInfo}
    }
}
</script>

<style lang="scss" scoped>
.affiliate{
    margin-top:16px;
    .affiliate_title {
        padding: 12px 16px;
        color: #0f0f0f;
        background: #fff;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
    }
    .affiliate_container{
       
        
        .container_thumb{
            margin-top:8px;
            padding:0 16px;
            background:#fff;
            border-radius: 4px;
            .thumb_title{
                text-align: left;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                padding:12px 0;
                border-bottom: 1px solid #e8e9ed;
            }
            .thumb_link{
                padding:16px 0;
                .link_name{
                    color: #5e626b;
                    font-size: 14px;
                    line-height: 22px;
                    text-align: left;
                }
                .link_group{
                    
                    .link_input{
                        width:270px;
                        height: 36px;
                        /deep/.el-input__inner{
                            height: 36px;
                        }
                    }
                    .link_copy{
                            background: #ffcc32;
                            cursor: pointer;
                            text-align: center;
                            box-sizing: border-box;
                            border-radius: 4px;
                            font-size: 14px;
                            min-width: 80px;
                            height: 36px;
                            line-height: 36px;
                            padding: 0 16px;
                            margin-left:12px;
                            &:hover{
                                opacity: 0.7;
                            }
                       .link_icon{
                            margin-right:6px;
                       } 
                    }
                }
            }
            .amount_thumb{
                .amount_group{
                    padding: 13px 0;
                    text-align: left;
                    &:nth-child(2){
                        margin-left: 64px;
                    }
                    .amount_name{
                        color: #5e626b;
                        font-size: 14px;
                        line-height: 22px;
                        
                    }
                    .amount_price{
                        color: #ff9500;
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 600;
                    }
                }
            }
            .title_tab{
                font-weight: 400;
                margin-right:40px;
                position: relative;
                cursor: pointer;
            }
            .is_active{
                &::before{
                    content: "";
                    position: absolute;
                    bottom: -12px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 24px;
                    height: 4px;
                    background-color: #ffd05a;
                }
            }
            .tabs_thumb{
                padding: 104px 0 179px;
                .empty_img{
                    width: 88px;
                }
                .empty_text{
                    margin-top: 20px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: center;
                    color: #77808c;
                }
            }
        }
    }
}
@media screen and (max-width:768px){
    .affiliate{
        margin:0 -16px;
    }
    .affiliate_title{
        border-radius: 0!important;
    }
}
</style>