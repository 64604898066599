<template>
  <div>
      <div class="pay_list" v-for="(item,index) in transResult" :key="index" id="pay_list">
     
      <!-- <div class="pay_list_title">{{ item.name}}</div> -->
      <div class="pay_list_mod" id="pay_list_mod">
        <!-- <div class="pay_mod_top flex_start_center"> -->
          <!-- <div class="mod_agree"></div> -->
          <!-- <div class="mod_img">
            <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000002FMfhF2vE2Nb0000UBEWLOG000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=ILh2aFpJyyKk7pj0ZxXKJZFq6s8%3D" alt="">
          </div> -->
        <!-- </div> -->
        <div class="form_content" v-for="(cur,idx) in item.tables" :key="idx">
          <el-form  label-width="80px" :model="payForm" ref="ruleForm" label-position="top">
            <el-form-item :label="head.name"  v-for="(head,headIdx) in cur.headers" :key="headIdx">
              <div v-if="head.control.type=='TextControl'" class="flex_col more_col">
                <el-input v-for="(control,conIdx) in cur.controls" :key="conIdx" v-model="control[headIdx].value" type="text"  @input="handleInput($event,index,idx,headIdx,conIdx)" :disabled="!head.control.configurationForResponder.editable"></el-input>
              </div>
              <div v-if="head.control.type=='PasswordControl'" class="flex_col more_col">
                <el-input v-for="(control,conIdx) in cur.controls" :key="conIdx" v-model="control[headIdx].value" type="password"  @input="handleInput($event,index,idx,headIdx,conIdx)" :disabled="!head.control.configurationForResponder.editable"></el-input>        
              </div>
               <div v-if="head.control.type=='NumericControl'" class="flex_col more_col">
                <el-input-number v-for="(control,conIdx) in cur.controls" :key="conIdx" size="mini" :min="control[0].minValue" :max="control[0].maxValue"  v-model="control[headIdx].value"   @change="handleInput($event,index,idx,headIdx,conIdx)" :disabled="!head.control.configurationForResponder.editable"></el-input-number>
              </div>
              <div v-if="head.control.type=='SingleSelectControl'" class="flex_warp">
                <div v-for="(control,conIdx) in cur.controls" :key="conIdx" class="flex_col more_col">
                  <div>
                    <el-radio v-model="control[headIdx].value" :label="rad" v-for="(rad,radIdx) in control[headIdx].options" :key="radIdx" @input="handleInput($event,index,idx,headIdx,conIdx)" :disabled="!head.control.configurationForResponder.editable">{{ rad }}</el-radio>
               
                  </div>
                 </div>
              </div>
              <div v-if="head.control.type=='DropDownSingleSelectControl'" >
                <div v-for="(control,conIdx) in cur.controls" :key="conIdx">
                  <el-select v-model="control[headIdx].value" placeholder="" @change="handleInput($event,index,idx,headIdx,conIdx)" :disabled="!head.control.configurationForResponder.editable" :id="'select'+headIdx">
                      <el-option
                      v-for="(sigItem,sig_index) in control[headIdx].options"
                      :key="sig_index"
                      :label="sigItem"
                      :value="sigItem">
                      </el-option>
                  </el-select>
                </div>
                  
              </div>
              <div v-if="head.control.type=='MultiSelectControl'">
                <div v-for="(control,conIdx) in cur.controls" :key="conIdx">
                  <el-checkbox-group v-model="control[headIdx].values" @change="handleInput($event,index,idx,headIdx,conIdx)" :disabled="!head.control.configurationForResponder.editable">
                    <el-checkbox :label="che" v-for="(che,cheIdx) in control[headIdx].options" :key="cheIdx"></el-checkbox> 
                  </el-checkbox-group>
                </div>
                 
              </div>
              <div v-if="head.control.type=='DropDownMultiSelectControl'">
                <div v-for="(control,conIdx) in cur.controls" :key="conIdx">
                  <el-select v-model="control[headIdx].values" multiple placeholder="" @change="handleInput($event,index,idx,headIdx,conIdx)" :disabled="!head.control.configurationForResponder.editable">
                      <el-option
                      v-for="milItem in control[headIdx].options"
                      :key="milItem"
                      :label="milItem"
                      :value="milItem">
                      </el-option>
                  </el-select>
                </div>
                  
              </div>
              <!-- head.control.maxImage -->
              <div class="up_content " v-if="head.control.type=='ImageControl'">
                <div v-for="(control,conIdx) in cur.controls" :key="conIdx" class="flex_warp" style="padding-bottom:8px">
                  <div v-if="!head.control.configurationForResponder.editable">
                    <div v-if="showPic && !head.control.configurationForResponder.editable" class="show_pic flex_warp">
                      <img v-for="(img,imgIdx) in control[headIdx].fileList" :key="imgIdx" :src="img.url" alt="" >
                    </div>
                  </div>
                  <div v-if="showPic&&head.control.configurationForResponder.editable">
                    <el-upload
                    :action="upUrl+'prod_api/api/common/uploadChain'"
                    list-type="picture-card"
                    :disabled="!head.control.configurationForResponder.editable"
                    :headers="config"
                    :data="upData"
                    :file-list="control[headIdx].fileList"
                    :on-success="(res,file,fileList)=>handleAvatarSuccess(control[headIdx],index,idx,headIdx,conIdx,res,file,fileList)"
                    :before-upload="beforeAvatarUpload"
                    :on-preview="handlePictureCardPreview"
                    :on-exceed="exceedCount"
                    :limit="head.control.maxImage"
                    :on-remove="(file,fileList)=>handleRemove(control[headIdx],index,idx,headIdx,conIdx,file,fileList)">
                    <i class="el-icon-plus" v-if="head.control.configurationForResponder.editable"></i>
                  </el-upload>
                  </div>
                </div>
              </div>
          </el-form-item>
          </el-form>
          <!-- <div class="radio_content flex">
            <div class="radio_label" @click="radio_select=!radio_select">
              <img v-if="radio_select" src="https://global-cdn.bigplayers.com/m/img/yellow/select.png" alt="">
              <img v-else src="https://global-cdn.bigplayers.com/m/img/yellow/no-select.png" alt=""> 
            </div>
            <div class="radio_msg">Remember the information above</div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
 
</template>

<script>

import cookies from '../utils/cookie'
import config_Api from '../../vue.config'

import $ from 'jquery'
import MD5 from 'md5'
import googleTranslate from 'google-translate-open-api'
export default {

  props:{
      result:{},
      transResult:{}
  },
         /* 
            控件类型：文本-TextControl
            密码-PasswordControl
            数字-NumericControl
            单选-SingleSelectControl
            单选下拉-DropDownSingleSelectControl
            多选-MultiSelectControl
            多选下拉-DropDownMultiSelectControl
            图片-ImageControl
            */ 
  data(){
      return{
          payForm:{},
          rules:{
              region: [ { required: false, message: '请选择活动区域', trigger: 'blur' }],
              id: [  { required: true, message: 'Enter ID', trigger: 'blur' } ],
              email: [  { required: true, message: 'Please enter your e-mail', trigger: 'blur' } ],
              name: [  { required: true, message: 'Enter the full name.', trigger: 'blur' } ],
              cpf: [  { required: true, message: 'Please enter the CPF', trigger: 'blur' } ],
          },
          
          dialogVisible:false,
          baseUrl:'',
          upData:{},
          objList:[],
          showPic:true,
          defaultPic:[],
          fileList:[],
          fileList0:[],
          fileList1:[],
          fileList2:[],
          fileList3:[],
          fileList4:[],
          fileList5:[],
          
          appId:'20200604000486108',
          key:'ioFs5BiJbAeCaW1jXXc6',
          fromLang:'zh',
          toLang:'en',
          // upUrl:'http://43.133.57.237:8090/',
          upUrl:'http://150.109.12.35:8090/',
      }
  },
  created(){
    // this.translate('<html><h1>china</h1></html>')
      let tables = this.result[0].tables
      let that = this
      let defaultPic =[]
      // this.upUrl = config_Api.devServer.proxy
      let lang = localStorage.getItem('lang')||'en'
      if(lang == 'ja'){
        this.toLang = 'jp'
      }
      else if(lang == 'in'){
        this.toLang = 'id'
      }else if(lang == 'zh'){
        this.toLang = 'cht'
      }
      else{
        this.toLang = lang
      }
     
    //  console.log("api==",config_Api.devServer.proxy)
    
      console.log('props....',this.result)
      // let  transResult =JSON.parse(JSON.stringify(this.result)) 
      //  transResult.map(r=>{
      //   r.tables.map((v,index)=>{   
      //     v.headers.map((i,i_idx)=>{
      //           let defPicObj = []
      //           let appid = this.appId
      //           let key = this.key
      //           let salt = (new Date).getTime()
      //           let query = i.name
      //           // 多个query可以用\n连接  如 query='apple\norange\nbanana\npear'
      //           let from = this.fromLang
      //           let to = this.toLang
      //           let str1 = appid + query + salt + key
      //           let sign = MD5(str1)
      //           let tranDataS 
      //           $.ajax({
      //               // url: 'http://api.fanyi.baidu.com/api/trans/vip/translate',
      //               url:'https://fanyi-api.baidu.com/api/trans/vip/translate',
      //               type: 'get',
      //               dataType: 'jsonp',
      //               data: {
      //                   q: query,
      //                   appid: appid,
      //                   salt: salt,
      //                   from: from,
      //                   to: to,
      //                   sign: sign
      //               },
      //               success: (data) => {
      //                 tranDataS = JSON.parse(JSON.stringify(data))
      //                 console.log('success',tranDataS)
      //                 let toText = tranDataS.trans_result[0].dst
      //                 i.name = toText
      //                 // let fromText = tranData.trans_result[0].src
      //                 // console.log('success',fromText,toText)
      //               }
      //           })
              
                
      //             console.log(' v.controls[i].options', v.controls)
      //             v.controls.map((control,control_idx)=>{
      //               if(control[i_idx].type=='DropDownSingleSelectControl'){
      //                 let transOptions = []
      //                 control[i_idx].options.map((o,o_index)=>{
                    
      //                   let obj = {}
      //                   let appid = this.appId
      //                   let key = this.key
      //                   let salt = (new Date).getTime()
      //                   let query = o
      //                   // 多个query可以用\n连接  如 query='apple\norange\nbanana\npear'
      //                   let from = this.fromLang
      //                   let to = this.toLang
      //                   let str1 = appid + query + salt + key
      //                   let sign = MD5(str1)
                    
      //                   let tranDataS 
      //                   $.ajax({
      //                       // url: 'http://api.fanyi.baidu.com/api/trans/vip/translate',
      //                       url:'https://fanyi-api.baidu.com/api/trans/vip/translate',                        
      //                       type: 'get',
      //                       dataType: 'jsonp',
      //                       data: {
      //                           q: query,
      //                           appid: appid,
      //                           salt: salt,
      //                           from: from,
      //                           to: to,
      //                           sign: sign
      //                       },
      //                       success: (data) => {
      //                         tranDataS = JSON.parse(JSON.stringify(data))
      //                         console.log('success',tranDataS)
      //                         let toText = tranDataS.trans_result[0].dst 
      //                         control[i_idx].options[o_index] = toText
      //                         this.$forceUpdate()
      //                         let fromText = tranDataS.trans_result[0].src
      //                         obj.toText = toText
      //                         obj.fromText = fromText
      //                         transOptions.push(obj)
      //                         control[i_idx].transOptions = transOptions
      //                         console.log('i.control.options[o_index]',control[i_idx].options,transOptions)
      //                       }
      //                   })
                      
      //                 })
      //               }
      //               if(control[i_idx].type == "ImageControl"){
      //                 control[i_idx].fileList = []
      //                 control[i_idx].values.map((t,t_idx)=>{
      //                 this.getPic(t,(data)=>{
      //                     let obj = {}
      //                     obj.url = data
      //                     console.log('configurationForResponder.editable',control[i_idx].configurationForResponder.editable)
      //                     defPicObj.push(obj)
      //                     this.fileList = defPicObj
      //                     control[i_idx].fileList.push(obj)
      //                     this.showPic =false
      //                     this.$forceUpdate()
      //                     this.$nextTick(()=>{
      //                       that.showPic = true
      //                     })
      //                   console.log('defaultPic,defaultPic', control[i_idx].fileList,this.result)
      //                   return t
      //                 }) 
      //               })
      //               } 
                   
      //               if(control[i_idx].type == "TextControl"&&!control[i_idx].configurationForResponder.editable){
      //                 let appid = this.appId
      //                 let key = this.key
      //                 let salt = (new Date).getTime()
      //                 let query = control[i_idx].value
      //                 // 多个query可以用\n连接  如 query='apple\norange\nbanana\npear'
      //                 let from = this.fromLang
      //                 let to = this.toLang
      //                 let str1 = appid + query + salt + key
      //                 let sign = MD5(str1)
      //                 let tranDataS 
      //                 $.ajax({ 
      //                     url:'https://fanyi-api.baidu.com/api/trans/vip/translate',
      //                     type: 'get',
      //                     dataType: 'jsonp',
      //                     data: {
      //                         q: query,
      //                         appid: appid,
      //                         salt: salt,
      //                         from: from,
      //                         to: to,
      //                         sign: sign
      //                     },
      //                     success: (data) => {
      //                       tranDataS = JSON.parse(JSON.stringify(data))
      //                       console.log('success',tranDataS)
      //                       let toText = tranDataS.trans_result[0].dst
      //                       control[i_idx].value = toText
      //                       // let fromText = tranData.trans_result[0].src
      //                       // console.log('success',fromText,toText)
      //                     }
      //                })

      //               } 
                    
                 
      //             })
                
                 
                  
               
              
                

              
             
              
              
            
      //     })
      //     return v
      //   })
      // })
      // this. transResult =  transResult
      this.$forceUpdate()
   
      this.$nextTick(()=>{
       
      })
  },
  mounted(){
    let that = this
    // this.$nextTick(()=>{
    //   let eles = [
    //       document.getElementById('pay_list'),to
    //       document.getElementById('pay_list_mod'),
    //       document.getElementById('c'),
    //   ];
    //   that.$translate.execute(eles)
    //   console.log('nextTick',that.$translate)
    // })
  },
  methods:{
    getPic(pic,callback){
      this.$axiosApi.getPic({pic}).then(res=>{
      if(res.code == 200){
        console.log(res.data)
        callback(res.data)
       return res.data
      }
    })
    },
      handleInput(value,resultIndex,tabIndex,headIndex,controlIndex){  
          let result = this.result
          let type = result[resultIndex].tables[tabIndex].headers[headIndex].control.type
          console.log('type',result[resultIndex].tables[tabIndex],)
          if(type == 'DropDownSingleSelectControl'){
            let options = result[resultIndex].tables[tabIndex].controls[controlIndex][headIndex].options
            let tranOptions = this.transResult[resultIndex].tables[tabIndex].controls[controlIndex][headIndex].options
            let itemIndex=0
            console.log('transOptions',tranOptions,)
            tranOptions.map((v,index)=>{
              if(v == value){
                itemIndex = index
              }
            })
            result[resultIndex].tables[tabIndex].controls[controlIndex][headIndex].value = options[itemIndex]
            console.log('resultoptions',options,options[itemIndex])
          }else{
            result[resultIndex].tables[tabIndex].controls[controlIndex][headIndex].value = value
          }
         
          console.log('value',value)
          this.$emit('input', result)
      },
      handleAvatarSuccess(item,resultIndex,tabIndex,headIndex,controlIndex,res,file,fileList) {
        console.log('上传成功',item,resultIndex,tabIndex,headIndex,controlIndex)
        if(res.code == 200){
          this.$message.success('success')
          let values =item.values
          values.push(res.data.objectId)
          let result = this.result
          result[resultIndex].tables[tabIndex].controls[controlIndex][headIndex].values = values
          
          console.log('item',item)
          console.log('file',file)
          
          console.log('values',values)
          console.log('fileList',fileList)
          // item.values =values
        }else{
          this.$message.error('Upload failed')
          fileList.map((v,index)=>{
            if(v.uid == file.uid){
              fileList.splice(index,1)
            }
          })
          return
        }
     
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(count,file,fileList) {
      // const isJPG = file.type === 'image/jpeg';
      // console.log(count,file,fileList)
      // const isLt2M = file.size / 1024 / 1024 < 2;

      // console.log('上传之前',file,fileList)
      // if (!isLt2M) {
      //   this.$message.error('The size of the uploaded image cannot exceed 2MB!');
      //   return false
      // }
     
      
    },
    handleRemove(item,resultIndex,tabIndex,headIndex,controlIndex,file, fileList) {
      console.log(item,resultIndex,tabIndex,headIndex,controlIndex,);
      item.values.map((v,index)=>{
        if(file.response.data.objectId == v){
          item.values.splice(index,1)
        }
      })
      let result = this.result
      result[resultIndex].tables[tabIndex].controls[controlIndex][headIndex].values.map(c=>{
        result[resultIndex].tables[tabIndex].controls[controlIndex][headIndex].values.splice(index,1)
      })
     console.log('item',item)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    exceedCount(){
      this.$message.warning('Exceeding the limit quantity')
      return
    },
    //  百度翻译入口
     translate(query,item) {
      var appid = '20241212002226547'
      var key = 'UpECBQaTHrgVPOCV8GBd'
      var salt = (new Date).getTime()
      var query = query
      // 多个query可以用\n连接  如 query='apple\norange\nbanana\npear'
      var from = 'en'
      var to = 'zh'
      var str1 = appid + query + salt + key
      var sign = MD5(str1)
      console.log('translate',sign)
      let tranDataS 
      $.ajax({
          url: 'http://api.fanyi.baidu.com/api/trans/vip/translate',
          type: 'get',
          dataType: 'jsonp',
          data: {
              q: query,
              appid: appid,
              salt: salt,
              from: from,
              to: to,
              sign: sign
          },
          success: (data) => {
            tranDataS = JSON.parse(JSON.stringify(data))
            console.log('success',tranDataS)
            let toText = tranDataS.trans_result[0].dst
          
            // let fromText = tranData.trans_result[0].src
            // console.log('success',fromText,toText)
          }
          
      });
      return tranDataS
    },
    // googole翻译
    async g_translate(query){
      let tranData = await googleTranslate(query,{
        tld:'zh',
        to:'en'
      })
      console.log('g_translate',tranData)
    }

  },
  computed:{
    config(){return {'authorization':cookies.getToken()}}
  }
}
</script>

<style lang="scss" scoped>
.pay_list{
  margin-top:16px;
  // margin-bottom:64px;
  .pay_list_title{
    font-size: 14px;
    color:#161619;
    font-weight: bold;
    margin-bottom:12px;
    text-align: left;
  }
  .pay_list_mod{
    border-radius: 6px;
    background:#fff;
  //   border:1px solid #f77e1a;
    .pay_mod_top{
      padding:10px;
      .mod_agree{
        width:20px;
        height: 20px;
        margin-right:14px;
        background:url(https://global-cdn.bigplayers.com/m/img/yellow/select.png);
        background-size: 100% 100%;
      }
      .mod_img{
        height: 32px;
        >img{
          height: 100%;
          width: auto;
        }
      }
    }
    .form_content{
      padding:0 12px 15px;
      .radio_content{
        color:#222425;
        .radio_label{
          width:20px;
          height: 20px;
          margin-right:14px;
        }
      }
      /deep/.el-form-item {
    margin-bottom:10px;
  }
  /deep/.el-form-item__label{
  //   font-size: 13px;
  //   color:#161619;
  //   height: 34px;
  //   padding:0;
      width:auto;
  }
  .suffix_icon{
    height: 100%;
    // transform: rotate(-90deg);
    translate: transform 0.35s ease-in-out;
  }
  .suffix_icon_rotate{
    transform: rotate(180deg)
  }
    }
  }
  
}
.show_pic{
width: 100%;
  >img{
    width:80px;
    height: 80px;
    padding:0 8px 8px 0;
  
  }
}
.up_content{
  padding:8px 0;
  div{
    display: flex;
    flex-wrap: wrap;
  }
}
/deep/.el-select{
  width: 100%;
}
/deep/.el-upload-list {
  display: flex;
  flex-wrap: wrap;
}
/deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
/deep/.el-upload--picture-card {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-upload-list--picture-card .el-upload-list__item{
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-status-label i {

  position: absolute;
  width: 12px;
  height: 12px;
  right: 14px;
  // top: 10px;
}
.el-checkbox-group {
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
}
/deep/.el-form-item__content {
 
  text-align: left;
}
.more_col{
>div{
  margin:2px 0;
}
}

</style>